import React, { useState } from "react";
import '../../styles/component/Tecnologia/Carat.scss'

export default function Carat({side, title, img, paragraph}){

    const [left, ] = useState(side === 'left' ? true : false)

    return(
        <>
            <div className = {side}>
                <h>{title}</h>
                <img alt = '' src = {img} />
            </div>
            <div className = 'text_carat'>
                <p style = {{maxWidth: '90%', margin: '20px auto', color: '#29235C', lineHeight: '130%'}}>{paragraph}</p>
            </div>
            {
                left ? <div className = {side + '_pc'}>
                            <div>
                                <img alt = '' src = {img} />
                            </div>
                            <div className = 'text_carat_pc'>
                                <h>{title}</h>
                                <p>{paragraph}</p>
                            </div>
                        </div>
                    : <div className = {side + '_pc'}>
                    <div className = 'text_carat_pc'>
                        <h>{title}</h>
                        <p>{paragraph}</p>
                    </div>
                    <div>
                        <img alt = '' src = {img} />
                    </div>
                </div>
            }
            
        </>
    )
}