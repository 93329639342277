import React, { useEffect, useRef } from 'react'
import '../../styles/component/Home/Caratteristiche.scss'
import carat1 from '../../assets/component/Home/Caratteristiche/Carat1.svg'
import carat2 from '../../assets/component/Home/Caratteristiche/Carat2.svg'
import carat3 from '../../assets/component/Home/Caratteristiche/Carat3.svg'
import carat4 from '../../assets/component/Home/Caratteristiche/Carat4.svg'
import carat5 from '../../assets/component/Home/Caratteristiche/Carat1PC.svg'
import carat6 from '../../assets/component/Home/Caratteristiche/Carat2PC.svg'
import carat7 from '../../assets/component/Home/Caratteristiche/Carat3PC.svg'
import carat8 from '../../assets/component/Home/Caratteristiche/Carat4PC.svg'

import {useInView} from 'react-intersection-observer'

export default function Caratteristiche() {

    const [refVideo, inViewVideo] = useInView({
        triggerOnce: false,
    })

    const videoRef = useRef(null)

    useEffect(() => {
        if (inViewVideo) {
            videoRef.current.play()
        } else {
            videoRef.current.pause()
            videoRef.current.currentTime = 0;
        }
    }, [inViewVideo])

    return(
        <>
            <div className = 'main_caratteristiche'>
                <div className='container_carat'>
                    <img alt = '' src = {carat1}></img>
                    <h>Pianificazione</h>
                    <div style = {{maxWidth: '90%'}}>
                        <p>Pianificazione ottimizzata della giornata lavorativa.</p>
                    </div>
                </div>
                <div className='container_carat'>
                    <img alt = '' src = {carat2}></img>
                    <h>Gestione</h>
                    <div style = {{maxWidth: '90%'}}>
                        <p>Controllo e gestione specifici dei flussi di magazzino</p>
                    </div>
                </div>
                <div className='container_carat'>
                    <img alt = '' src = {carat3}></img>
                    <h>Tracciamento</h>
                    <div style = {{maxWidth: '90%'}}>
                        <p>Prodotti e componenti tracciati in ogni momento.</p>
                    </div>
                </div>
                <div className='container_carat'>
                    <img alt = '' src = {carat4}></img>
                    <h>Comunicazione</h>
                    <div style = {{maxWidth: '90%'}}>
                        <p>Maggior comunicare tra i dipendenti.</p>
                    </div>
                </div>
            </div>
            <div className = 'main_caratteristiche_pc'>
                <div className = 'title_caratteristiche_pc'>
                    <h1>La soluzione software completa per il tuo business</h1>
                </div>
                <div className = 'container_caratteristiche_pc'>
                    <div className = 'carat_pc'>
                        <img alt = '' src = {carat5} />
                        <p>Pianificazione</p>
                        <p1>Pianificazione ottimizzata della giornata lavorativa.</p1>
                    </div>
                    <div className = 'carat_pc'>
                        <img alt = '' src = {carat6} />
                        <p>Gestione</p>
                        <p1>Controllo e gestione specifici dei flussi di magazzino</p1>
                    </div>
                    <div className = 'carat_pc'>
                        <img alt = '' src = {carat7} />
                        <p>Tracciamento</p>
                        <p1>Prodotti e componenti tracciati in ogni momento.</p1>
                    </div>
                    <div className = 'carat_pc'>
                        <img alt = '' src = {carat8} />
                        <p>Comunicazione</p>
                        <p1>Maggior comunicare tra i dipendenti.</p1>
                    </div>
                </div>
            </div>
            <div className = 'video_container' ref={refVideo}>
                <h1>Il nostro <span style = {{color: '#F39200'}}> servizio</span></h1>
                <video ref = {videoRef} src={`${process.env.REACT_APP_DOMAIN_URL}/DYNAMIC_INV_V3.mp4`} className = 'video' muted playsinline=''>
                    Your browser does not support the video tag.
                </video>
            </div>
        </>
    )
}