import React from 'react'
import {Link} from 'react-router-dom'

import '../styles/component/Footer.scss'
import logoNeurally from '../assets/component/Footer/logo-neurally.svg'
import linkedin from '../assets/component/Footer/linkedin.svg'
import insta from '../assets/component/Footer/insta.svg'
import X from '../assets/component/Footer/X.svg'

export default function Footer() {

    const handlePhoneNumber = (e) => {
        const phoneLink = document.createElement('a');
        phoneLink.href = `tel:${e.target.innerText}`;
        document.body.appendChild(phoneLink);
        phoneLink.click();
        document.body.removeChild(phoneLink);
    }

    const handleGoogleMaps = (e) => {
        const mapsLink = document.createElement('a');
        mapsLink.href = `https://www.google.com/maps/search/?api=1&query=${e.target.innerText}`;
        document.body.appendChild(mapsLink);
        mapsLink.click();
        document.body.removeChild(mapsLink);
    }

    return(
        <div className = 'footer'>
            <div className = 'footer_title'>
                <img alt = '' src = {logoNeurally} />
            </div>
            <div className = 'footer_background'>
                <div className = 'footer_info_container'>
                    <div className = 'footer_info_column'>
                        <div className = 'info_footer'>
                            <h>Neurally S.r.l.</h>
                            <p>Capitale sociale 10.000€ (i.v.)</p>
                            <p>PI e CF: 02062960386</p>
                        </div>
                        <div className = 'info_footer'>
                            <h>SEDE LEGALE E OPERATIVA</h>
                            <p onClick={handleGoogleMaps}>Via L.V. Beethoven 15/C</p>
                            <p>44124 Ferrara (FE)</p>
                            <p>Presso Le Corti di Medoro</p>
                        </div>
                        <div className = 'info_footer'>
                            <h>CONTATTO</h>
                            <p>info@neurally.it</p>
                            <p onClick={handlePhoneNumber}>+39 <span>0532 1717097</span></p>
                        </div>
                    </div>
                    <div className = 'footer_container_social'>
                        <Link to = 'https://www.linkedin.com/company/neurally/' target='_blank' >
                            <img alt = '' src = {linkedin}/>
                        </Link>
                        <Link to = 'https://www.instagram.com/neurally.it/' target='_blank' >
                            <img alt = '' src = {insta}/>
                        </Link>
                        <Link to = 'https://twitter.com/neurally_it' target='_blank'>
                            <img alt = '' src = {X}/>
                        </Link>
                    </div>
                </div>
            </div>
            <div className = 'footer_background_pc'>
                <div className = 'left_footer'>
                    <div className = 'info_footer'>
                        <h>Neurally S.r.l.</h>
                        <p>Capitale sociale 10.000€ (i.v.)</p>
                        <p>PI e CF: 02062960386</p>
                    </div>
                    <div className = 'footer_container_social'>
                        <Link to = 'https://www.linkedin.com/company/neurally/' target='_blank' >
                            <img alt = '' src = {linkedin}/>
                        </Link>
                        <Link to = 'https://www.instagram.com/neurally.it/' target='_blank' >
                            <img alt = '' src = {insta}/>
                        </Link>
                        <Link to = 'https://twitter.com/neurally_it' target='_blank'>
                            <img alt = '' src = {X}/>
                        </Link>
                    </div>
                </div>
                <div className = 'right_footer'>
                    <div className = 'info_footer'>
                        <h>SEDE LEGALE E OPERATIVA</h>
                        <p onClick={handleGoogleMaps} style = {{cursor: 'pointer'}}>Via L.V. Beethoven 15/C</p>
                        <p>44124 Ferrara (FE)</p>
                        <p>Presso Le Corti di Medoro</p>
                    </div>
                    <div className = 'info_footer'>
                        <h>CONTATTO</h>
                        <p>info@neurally.it</p>
                        <p onClick={handlePhoneNumber} style = {{cursor: 'pointer'}}>+39 <span>0532 1717097</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}