import './styles/global.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Header from './component/Header';
import Home from './pages/Home';
import Tecnologia from './pages/Tecnologia';
import Contatti from './pages/Contatti';

function App() {
  return (
    <BrowserRouter>
      <div style = {{display: 'flex', justifyContent: 'center'}}>
        <Header />
        <Routes>
          <Route path = '/' element = {<Home />}/>
          <Route path = '/tecnologia' element = {<Tecnologia />} />
          <Route path = '/contattaci' element = {<Contatti />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
