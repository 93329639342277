import React, { useEffect, useState } from 'react'
import '../styles/pages/Contatti.scss'
import Footer from '../component/Footer'

export default function Contatti() {

    const defaultForm = {
        name: 'Nome*',
        surname: 'Cognome*',
        email: 'Email*',
        phoneNumber: 'Numero di telefono*',
        company: 'Azienda', 
        message: 'Scrivi...',
    }

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phoneNumber: '',
        company: '', 
        message: '',
    })

    const [loading, setLoading] = useState(false)
    const [sended, setSended] = useState(false)

    const [error, setError] = useState(
        {
            name: false,
            surname: false,
            email: false, 
            phoneNumber: false, 
            message: false,
        }
    )

    const handleChange = (e) => {  
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }


    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        for (const key in formData){
            if(!formData[key]){
                setError((error) => ({
                    ...error,
                    [key]: true,
                  }));
            } else {
                setError((error) => ({
                    ...error,
                    [key]: false,
                  }));
            }
        }

        const payload = {
            "fullname":formData.name + ' ' + formData.surname,
            "email":formData.email,
            "phone_number":formData.phoneNumber,
            "company":formData.company,
            "message":formData.message
        }

        if (formData.name && formData.surname && formData.phoneNumber && formData.email && formData.message){
            apiRequest(payload)
        }

    }

    const apiRequest = async (payload) => {
        try {
            setLoading(true)
            await fetch(process.env.REACT_APP_API_CONTACTS, {
                headers: {"Content-Type": "application/json; charset=utf-8"},
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => {
                return response.json();
            }).then((data) => {
                setFormData(defaultForm)
                setSended(true)
            }).catch((error) => {
                console.log(error)
                console.log(error.response)
                return error
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className = 'main_contatti'>
            <div style ={{marginTop: '5vh'}}>
                <h>Contattaci!</h>
            </div>

            <form onSubmit={handleSubmit} className = 'formCustom'>
                <div className = 'input_fullName'>
                    <input 
                        type='text'
                        name='name'
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={defaultForm.name}
                        className = 'input'
                        style = {{
                            border: error.name ? '2px solid red' : '',
                        }}
                    />
                    <input 
                        type='text'
                        name='surname'
                        value={formData.surname}
                        onChange={handleChange}
                        placeholder={defaultForm.surname}
                        className = 'input'
                        style = {{
                            border: error.surname ? '2px solid red' : '',
                        }}
                    />
                </div>
                <div className='input_fullName'>
                    <input 
                        type='text'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={defaultForm.email}
                        className = 'input'
                        style = {{
                            border: error.email ? '2px solid red' : '',
                        }}
                    />
                    <input 
                        type='text'
                        name='phoneNumber'
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder={defaultForm.phoneNumber}
                        className = 'input'
                        style={{
                            border: error.phoneNumber ? '2px solid red' : '', 
                          }}
                    />
                </div>
                <div className = 'input_fullName'>
                    <input 
                        type='text'
                        name='company'
                        value={formData.company}
                        onChange={handleChange}
                        placeholder={defaultForm.company}
                        className = 'input'
                    />
                </div>
                <div style = {{height: 'fit-content', width: '100%'}}>
                    <p>Messaggio</p>
                    <textarea
                        name = 'message'
                        value={formData.message}
                        onChange={handleChange}
                        placeholder={defaultForm.message}
                        className = 'text-area'
                        style = {{
                            border: error.message ? '2px solid red' : '',
                        }}
                    />
                </div>

                {
                    sended ? <div style={{display: 'flex', gap:'10px', alignItems: 'center'}}>
                            <p1 className = 'sended'> Messaggio inviato.</p1>
                        </div>
                    : <div style={{display: 'flex', gap:'10px', alignItems: 'center'}}>
                            <button type='submit' className = 'submit'>INVIA</button>
                            {
                                loading ? <div className = 'loader' />
                                : <></>
                            }
                        </div>
                }
                
            </form>
            <div className = 'footer_text'>
                <p>
                    Scopri Dynamic Inventory oggi e <span>trasforma </span> la tua gestione operativa
                    in un <span> processo fluido e efficiente</span>!
                </p>
            </div>
            <Footer />
        </div>
    )
}