import React from 'react'
import '../../styles/component/Tecnologia/CardWhite.scss'

export default function CardWhite({img, title, paragraph}){
    return (
        <div className = 'main_card_white'>
            <div className = 'text_card_white'>
                <h>{title}</h>
                <p>{paragraph}</p>
            </div>
            <img alt = '' src = {img} />
        </div>
    )
}