import React, { useEffect } from 'react'
import '../styles/pages/Home.scss'
import Title from '../component/Home/Title'
import Caratteristiche from '../component/Home/Caratteristiche'
import Workflow from '../component/Home/Workflow'
import Separator from '../component/Home/Separator'
import Footer from '../component/Footer'

export default function Home() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <div className = 'main_home'>
            <div className = 'main_intro'>
                <Title />
                <Caratteristiche />
            </div>
            <div className = 'main_content_sep'>
                <Separator />
            </div>
            <div className = 'main_content'>
                <Workflow />
            </div>
            <Footer />
        </div>
    )
}