import React from "react";
import {Link} from 'react-router-dom'
import '../../styles/component/Home/Workflow.scss'

import pc from '../../assets/component/Home/Workflow/PC.svg'
import logo from '../../assets/component/Home/Workflow/logo_workflow.svg'
import first_car from '../../assets/component/Home/Workflow/Car1.svg'
import second_car from '../../assets/component/Home/Workflow/Car2.svg'
import mobile1 from '../../assets/component/Home/Workflow/Mobile1.svg'
import mobile3 from '../../assets/component/Home/Workflow/Mobile3.svg'
import mobile2 from '../../assets/component/Home/Workflow/Mobile2.svg'
import freccia from '../../assets/component/Home/Workflow/freccia.svg'
import hardware from '../../assets/component/Home/Workflow/Hardware.svg'
import hardware1 from '../../assets/component/Home/Workflow/hardware1.svg'
import trasporto from '../../assets/component/Home/Workflow/trasporto.svg'
import firma from '../../assets/component/Home/Workflow/firma.svg'
import vantaggio1 from '../../assets/component/Home/Workflow/vantaggio1.svg'
import vantaggio2 from '../../assets/component/Home/Workflow/vantaggio2.svg'
import vantaggio3 from '../../assets/component/Home/Workflow/vantaggio3.svg'

import {useInView} from 'react-intersection-observer'

export default function Workflow() {

    const [refTitle, inViewTitle] = useInView({
        triggerOnce: true,
    });

    const [refImageTitle, inViewImageTitle] = useInView({
        triggerOnce: true,
    })

    const [refImageTitle2, inViewImageTitle2] = useInView({
        triggerOnce: true,
    })

    const [refSecondCar, inViewSecondCar] = useInView({
        triggerOnce: true,
    })

    const [refBodySecondCar, inViewBodySecondCar] = useInView({
        triggerOnce: true,
    })

    const [refMobileImagePc, inViewMobileImagePc] = useInView({
        triggerOnce: true,
    })

    const [refBodyMobilePc, inViewBodyMobilePc] = useInView( {
        triggerOnce: true,
    })

    const [refFrecciaPc, inViewFrecciaPc] = useInView({
        triggerOnce: true,
    })

    const [refHardwarePc, inViewHarwarePc] = useInView({
        triggerOnce: true,
    })

    const [refFirmaPc, inViewFirmaPc] = useInView({
        triggerOnce: true,
    })

    const [refFooterPc, inViewFooterPc] = useInView({
        triggerOnce: true,
    })

    const [refImageMobile, inViewImageMobile] = useInView({
        triggerOnce: true, 
    })

    const [refTextMobile, inViewTextMobile] = useInView({
        triggerOnce: true,
    })

    const [refImageMobileSequence, inViewImageMobileSequence] = useInView({
        triggerOnce: true,
    })

    const [refMobile, inViewMobile] = useInView({
        triggerOnce: true,
    })

    const [refMobile2, inViewMobile2] = useInView({
        triggerOnce: true,
    })

    const [refFrecciaMobile, inViewFrecciaMobile] = useInView({
        triggerOnce: true,
    })

    const [refHardwareMobile, inViewHardwareMobile] = useInView( {
        triggerOnce: true,
    })

    const [refFirmaMobile, inViewFirmaMobile] = useInView({
        triggerOnce: true,
    })

    const [refFooterMobile, inViewFooterMobile] = useInView({
        triggerOnce: true,
    })
    return (
        <>
            <div className = 'main_workflow'>
                <div className = "title">
                    <h>Come funziona?</h>
                </div>
                <div className = 'first_image'>
                    <img ref = {refImageMobile} className = {inViewImageMobile ? 'slowRightFast' : ''} style = {{opacity: '0'}} alt = '' src = {pc} />
                    <img ref = {refImageMobile} className = {inViewImageMobile ? 'slowLeftFast' : ''} style = {{opacity: '0'}} alt = '' src = {logo} />
                </div>
                <div className ="text">
                    <h ref = {refTextMobile} className = {inViewTextMobile ? 'slowRightFast' : ''} style = {{opacity: '0'}}>Pannello di controllo</h>
                    <div style = {{width: '65%'}}>
                        <p ref = {refTextMobile} className = {inViewTextMobile ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Crea abilmente i compiti specifici per i tuoi dipendenti
                            direttamente dal pannello di controllo cloud.
                        </p>
                        <p ref = {refTextMobile} className = {inViewTextMobile ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Specifica i dettagli, come la destinazione, il veicolo 
                            da utilizzare e i pezzi necessari.
                        </p>
                    </div>
                </div>
                <div className = "first_car" >
                    <img ref={refImageMobileSequence} className = {inViewImageMobileSequence ? 'slowOblique' : ''} style = {{opacity: '0'}} alt = '' src = {first_car} />
                </div>
                <div className = "second_car" >
                    <img ref={refImageMobileSequence} className = {inViewImageMobileSequence ? 'slowOblique' : ''} style = {{opacity: '0'}} alt = '' src = {second_car} />
                </div>
                <div className = 'mobile'>
                    <img ref = {refMobile} className={inViewMobile ? 'slowZ' : ''} style = {{opacity: '0'}} alt = '' src = {mobile1} />
                    <div className = "mobile_container">
                        <h ref = {refMobile} className={inViewMobile ? 'slowLeftFast' : ''} style = {{opacity: '0'}}>Incarichi assegnati</h>
                        <p  ref = {refMobile} className={inViewMobile ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Il sistema comunica automaticamente i copmiti ai dipendenti 
                            indicati, garantendo una distribuzione efficiente delle risorse.
                        </p>
                    </div>
                </div>
                <div className = 'mobile'>
                    <div className = "mobile_container">
                        <h ref = {refMobile2} className={inViewMobile2 ? 'slowRightFast' : ''} style = {{opacity: '0'}}>App operativa</h>
                        <p ref = {refMobile2} className={inViewMobile2 ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Attraverso un'app specifica, i tuoi dipendenti apprendono le istruzioni dettagliate
                            circa i compiti.
                        </p>
                        <p ref = {refMobile2} className={inViewMobile2 ? 'slowDown' : ''} style = {{textDecoration: 'underline 1px #ffffff', opacity: '0'}}>
                            Dotazione della lista completa dell'inventario e della mappa interattiva per 
                            localizzare i colleghi e le componenti presenti in ciascun mezzo.
                        </p>
                    </div>
                    <img ref = {refMobile2} className={inViewMobile2 ? 'slowZ' : ''} style = {{opacity: '0'}} alt = '' src = {mobile3} />
                </div>
                <div className = "arrow">
                    <img ref={refFrecciaMobile} className = {inViewFrecciaMobile ? 'slowObliqueDown' : ''} style = {{opacity: '0'}} alt = '' src = {freccia} />
                </div>
                <div className = 'hardware'>
                    <img ref={refHardwareMobile} className={inViewHardwareMobile ? 'slowZ' : ''} style = {{opacity: '0'}} alt = '' src = {hardware} />
                    <div style = {{maxWidth: '45vw'}}>
                        <h ref={refHardwareMobile} className={inViewHardwareMobile ? 'slowLeftFast' : ''} style = {{opacity: '0'}}>Sistema hardware</h>
                        <p ref={refHardwareMobile} className={inViewHardwareMobile ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Tracciamento in tempo reale dei veicoli e degli 
                            inventari grazie alla tecnologia RFID UHF
                            direttamente installata sui mezzi e sulle singole componenti.
                        </p>
                    </div>
                </div>
                <div className = 'double_image'>
                    <img alt = '' src = {trasporto} className = 'image1'/>
                    <img alt = '' src = {mobile2} />
                </div>
                <div className = 'firma'> 
                    <div className = "firma_container">
                        <h ref = {refFirmaMobile} className = {inViewFirmaMobile ? 'slowRightFast' : ''} style = {{opacity: '0'}}>Firma e conferma</h>
                        <p ref = {refFirmaMobile} className = {inViewFirmaMobile ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Una volta completato il compito, i tuoi dipendenti firmano digitalmente
                            il foglio di chiusura tramite l'app. 
                            Ricevi una conferma istantanea attraverso il nostro sistema cloud.
                        </p>
                    </div>
                    <img ref = {refFirmaMobile} className = {inViewFirmaMobile ? 'slowLeftFast' : ''} style = {{opacity: '0'}} alt = '' src = {firma}/>
                </div>
                <div className = 'vantaggi'>
                    <h>Vantaggi</h>
                    <div className = 'carat_vantaggi'>
                        <div className = 'card'>
                            <img alt = '' src = {vantaggio1} />
                            <p>Efficienza operativa</p>
                        </div>
                        <div className = 'card'>
                            <img alt = '' src = {vantaggio2} />
                            <p>Comunicazione Facilitata</p>
                        </div>
                        <div className = 'card'>
                            <img alt = '' src = {vantaggio3} />
                            <p>Tracciamento preciso</p>
                        </div>
                    </div>
                </div>
                <div className = 'mappa' />
                <div className = 'contatti'>
                    <div style = {{width: '50%'}}>
                        <h ref = {refFooterMobile} className = {inViewFooterMobile ? 'slowDownFast' : ''} style = {{opacity: '0'}}>Sei pronto a iniziare?</h>
                    </div>
                    <Link ref = {refFooterMobile} style = {{opacity: '0'}} to = '/contattaci' className = {`contatti_button ${inViewFooterMobile ? 'slowUpFast' : ''}`}>
                        Contattaci
                    </Link>               
                </div>
            </div>


            <div className="main_workflow_pc">
                <div className ="title_pc">
                    <h>Come funziona?</h>
                </div>
                <div className = 'intro_pc'>
                    <div style = {{display: 'flex', flexDirection: 'column', maxWidth: '45%', gap: '20px'}}>
                        <img ref = {refImageTitle} className = {inViewImageTitle ? 'slowRight' : ''} alt = '' src = {pc} style = {{height: '248px', width: '195px', marginBottom: '30px', opacity: '0'}}/>
                        <h1 ref = {refImageTitle} className = {inViewImageTitle ? 'slowRight' : ''} style = {{opacity: '0'}}>Pannello di controllo</h1>
                        <p ref = {refTitle} className = {inViewTitle ? 'slowDown' : ''} style = {{opacity:'0'}}>Crea abilmente i compiti specifici per i tuoi dipendenti direttamente dal <span>pannello di controllo cloud</span>.</p>
                        <p ref = {refTitle} className = {inViewTitle ? 'slowDown' : ''} style = {{opacity:'0'}}>Specifica i dettagli, come la destinazione, il veicolo da utilizzare e i pezzi necessari.</p>
                    </div>
                    <div ref={refImageTitle2} className = {inViewImageTitle2 ? 'slowLeftSpeed' : ''} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: '0'}}>
                        <img alt = '' src={logo} style = {{height: '409px', width: '325px'}}/>
                        <img alt = '' src={first_car} style = {{height: '378px', width: '494px', marginTop: '50px'}} />
                    </div>
                </div>
                <div className = "intro2_pc">
                    <img ref= {refSecondCar} className = {inViewSecondCar ? 'slowOblique' : ''} style = {{opacity: '0'}} alt = '' src = {second_car}/>
                    <div style = {{display: 'flex', flexDirection: 'column', maxWidth: '45%', gap: '20px'}}>
                        <h1 ref= {refSecondCar} className = {inViewSecondCar ? 'slowLeftSpeed' : ''}>Incarichi assegnati</h1>
                        <p ref = {refBodySecondCar} className = {inViewBodySecondCar ? 'slowDown' : ''}>Il sistema comunica automaticamente i compiti ai dipendenti indicati, garantendo una distribuzione efficiente delle risorse.</p>
                    </div>
                </div>
                <div className = 'mobile_pc'>
                    <div className = "mobile_text_pc">
                        <h ref = {refBodyMobilePc} className = {inViewBodyMobilePc ? 'slowRight' : ''} style = {{opacity: '0'}}>
                            App operativa
                        </h>
                        <p ref = {refBodyMobilePc} className = {inViewBodyMobilePc ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Attraverso un'app specifica, i tuoi dipendenti
                            apprendono le istruzioni dettagliate circa i propri compiti.
                        </p>
                        <p ref = {refBodyMobilePc} className = {inViewBodyMobilePc ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            <span>
                                Dotazione della lista completa dell'inventario e della mappa 
                                interattiva per localizzare i colleghi e le componenti presenti in 
                                ciascun mezzo.
                            </span>
                        </p>
                    </div>
                    <div ref={refMobileImagePc} className ={`mobile_image_pc ${inViewMobileImagePc ? 'slowZ' : ''}`} >
                        <div>
                            <img alt = '' src = {mobile3}/>
                        </div>
                        <div style = {{marginBottom: '80px'}}>
                            <img alt = '' src = {mobile1}/>
                        </div>
                    </div>
                </div>
                <div className = "arrow">
                    <img ref = {refFrecciaPc} className = {inViewFrecciaPc ? 'slowObliqueDown' : ''} style = {{opacity: '0'}} alt = '' src = {freccia}/>
                </div>
                <div  className = "hardware">
                    <div style = {{maxWidth: '45%', display: 'flex', flexDirection: 'column', marginRight: '150px', gap: '20px'}}>
                        <h ref= {refHardwarePc} className = {inViewHarwarePc ? 'slowLeftSpeed' : ''} style = {{opacity: '0'}}>Sistema hardware</h>
                        <p ref= {refHardwarePc} className = {inViewHarwarePc ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Tracciamento in tempo reale dei veicoli e degli 
                            inventari grazie alla tecnologia RFID UHF
                            direttamente installata sui mezzi e sulle singole componenti.
                        </p>
                    </div>
                </div>
                <div className = "image_hardware_pc">
                    <img alt = '' src = {hardware} style = {{height: '275.58px', width: '411px'}}/>
                    <img alt = '' src = {hardware1} style = {{marginTop: '100px'}} />
                    <img alt = '' src = {mobile2} style = {{height: '516px', width: '238px', marginTop: '100px'}}/>
                </div>
                <div className="mappa_pc">
                    <img alt = '' src={trasporto} style = {{width: '558px', height: '474px'}}/>
                </div>
                <div className = 'firma_pc'> 
                    <div className = "firma_container">
                        <h ref = {refFirmaPc} className = {inViewFirmaPc ? 'slowRight' : ''} style = {{opacity: '0'}}>Firma e conferma</h>
                        <p ref = {refFirmaPc} className = {inViewFirmaPc ? 'slowDown' : ''} style = {{opacity: '0'}}>
                            Una volta completato il compito, i tuoi dipendenti firmano digitalmente
                            il foglio di chiusura tramite l'app. 
                            Ricevi una conferma istantanea attraverso il nostro sistema cloud.
                        </p>
                    </div>
                    <img ref = {refFirmaPc} className = {inViewFirmaPc ? 'slowLeftSpeed' : ''} alt = '' src = {firma} style = {{height: '441px', width: '441px', opacity: '0'}}/>
                </div>
                <div className = 'vantaggi_pc'>
                    <h>Vantaggi</h>
                    <div className = 'carat_vantaggi_pc'>
                        <div className = 'card_pc'>
                            <img alt = '' src = {vantaggio1} style = {{height: '148px', width: '148px'}}/>
                            <p>Efficienza operativa</p>
                        </div>
                        <div className = 'card_pc'>
                            <img alt = '' src = {vantaggio2} style = {{height: '124px', width: '124px'}}/>
                            <p>Comunicazione Facilitata</p>
                        </div>
                        <div className = 'card_pc'>
                            <img alt = '' src = {vantaggio3} style = {{height: '152px', width: '152px'}}/>
                            <p>Tracciamento preciso</p>
                        </div>
                    </div>
                </div>
                <div className = 'mappa' />
                <div className = 'contatti'>
                    <div style = {{width: '50%'}}>
                        <h ref = {refFooterPc} className = {inViewFooterPc ? 'slowDownSpeed' : ''} style = {{opacity: '0'}}>Sei pronto a iniziare?</h>
                    </div>
                    <Link  ref = {refFooterPc} to = '/contattaci' className = {`contatti_button ${inViewFooterPc ? 'slowUp' : ''}`}>
                        Contattaci
                    </Link>               
                </div>
            </div>
        </>
    )
}