import React from 'react'
import '../../styles/component/Tecnologia/Title.scss'

export default function Title() {
    return (
        <div className = 'main_title_tech'>
            <div className = 'text_tech'>
                <h>Cos'è <br/> Dynamic Inventory?</h>
            </div>
            <div className = 'paragraph_tech'>
                <p>
                    Dynamic Inventory è un servizio software progettato per aziende
                    che operano in settori che richiedono una gestione dinamica degli
                    inventari, come consegna di merce, riparazioni, installazioni e altri
                    servizi basati sulla mobilità.
                </p>
                <p>
                    Ecco una <span>panoramica delle funzionalità chiave </span> 
                    di Dynamic Inventory:
                </p>
            </div>
            <div className = 'text_tech_pc'>
                <h>Cos'è Dynamic Inventory?</h>
                <p>Dynamic Inventory è un servizio software progettato per aziende che operano in
                    settori che richiedono una gestione dinamica degli inventari, come consegna di 
                    merce, riparazioni, installazioni e altri servizi basati sulla mobilità.
                </p>
                <p>Ecco una <span>panoramica delle funzionalità chiave</span> di Dynamic Inventory.</p>
            </div>
            <div className = 'image_pc' />
        </div>
    )
}