import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import '../styles/pages/Tecnologia.scss'
import Title from '../component/Tecnologia/Title'
import CardBlue from '../component/Tecnologia/CardBlue'
import CardWhite from '../component/Tecnologia/CardWhite'
import incarichi from '../assets/component/Tecnologia/CardBlue/incarichi.svg'
import assegnazione from '../assets/component/Tecnologia/CardWhite/assegnazione.svg'
import tecnologia from '../assets/component/Tecnologia/CardBlue/tecnologia.svg'
import lista from '../assets/component/Tecnologia/CardWhite/lista.svg'
import mappa from '../assets/component/Tecnologia/CardBlue/mappa.svg'
import firma from '../assets/component/Tecnologia/CardWhite/firma.svg'
import cloud from '../assets/component/Tecnologia/Carat/cloud.svg'
import app from '../assets/component/Tecnologia/Carat/app.svg'
import hardware from '../assets/component/Tecnologia/Carat/hardware.svg'
import Separator from '../component/Tecnologia/Separator'
import Carat from '../component/Tecnologia/Carat'
import Footer from '../component/Footer'
import Card from '../component/Tecnologia/Card'

export default function Tecnologia() {

    const [title1, ] = useState('Creazione incarichi')
    const [paragraph1, ] = useState("L'amministrazione aziendale utilizza il cloud per creare compiti specifici, indicando l'itinerario, il veicolo da utilizzare e i materiali a pezzi da caricare per effettuare il servizio.")
    const [title2, ] = useState('Assegnazione incarichi')
    const [paragraph2, ] = useState("Ogni incarico viene assegnato a ciascun dipendente attraverso un'applicazione dedicata.")
    const [title3, ] = useState('Tecnologia UHF RFID')
    const [paragraph3, ] = useState("Dinamic Inventory sfrutta la tecnologia UHF (Ultra High Frequency) di RFID (Radio-Frequency Identification) per tracciare e gestire gli inventari. Questa tecnologia può essere installata diretamente sui veicoli e sui componenti dell'inventario.")
    const [title4,] = useState('Lista automatizzata inventario')
    const [paragraph4, ] = useState("Ogni dipendente, tramite l'app, accede a una lista dinamica e automatizzata degli elementi necessari per il compito assegnato, con informazioni sulla posizione dei colleghi che hanno pezzi utili per l'intervento.")
    const [title5, ] = useState('Mappa interattiva')
    const [paragraph5, ] = useState("Attraverso l'app, i dipendenti possono visualizzare sulla mappa la posizione dei colleghi e la disponibilità dei pezzi di inventario. Questo aiuta a ottimizzare gli spostamenti e a facilitare la collaborazione tra i membri del team.")
    const [title6, ] = useState('Conferma incarico completato')
    const [paragraph6, ] = useState("L'amministrazione aziendale utilizza il cloud per creare compiti specifici, indicando l’itinerario, il veicolo da utilizzare e i materiali o pezzi da caricare effettuare il servizio.")
    const [title7, ] = useState('Cloud')
    const [paragraph7, ] = useState("Dynamic Inventory poggia su un robusto e sicuro sistema basato su cloud, che rappresenta il cuore pulsante della nostra tecnologia. Questa piattaforma avanzata consente all'amministrazione aziendale di creare, assegnare e monitorare i compiti in tempo reale, offrendo un controllo senza precedenti sulla gestione operativa. Con il nostro cloud, le informazioni sono accessibili istantaneamente da qualsiasi luogo, consentendo una gestione flessibile e una reattività immediata alle esigenze in continua evoluzione del tuo business.")
    const [title8, ] = useState("App")
    const [paragraph8, ] = useState("La nostra app, parte integrante di Dynamic Inventory, è progettata per essere l'assistente mobile perfetto per i tuoi dipendenti in movimento. Con un'interfaccia intuitiva, fornisce istruzioni dettagliate sui compiti assegnati, mettendo a disposizione dei dipendenti una lista automatizzata dell'inventario specifico per ciascuna attività. La mappa interattiva all'interno dell'app facilita la localizzazione dei colleghi e dei pezzi di inventario, garantendo una comunicazione chiara e una collaborazione senza intoppi.")
    const [title9, ] = useState('Hardware')
    const [paragraph9, ] = useState("Al cuore della nostra tecnologia si trova un sistema hardware all'avanguardia, alimentato dalla tecnologia UHF di RFID. Questo sistema consente il tracciamento in tempo reale dei veicoli e degli inventari, fornendo una visibilità completa e precisa. Installato sia sui veicoli che sui componenti dell'inventario, il nostro hardware assicura una gestione efficiente e sicura, riducendo i rischi di errori e ottimizzando l'utilizzo delle risorse aziendali. Con Dynamic Inventory, il nostro sistema hardware è la spina dorsale affidabile su cui puoi contare per una gestione operativa impeccabile.")
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


    return(
        <div className = 'main_tecnologia'>
            <Title />
            <CardBlue img={incarichi} title={title1} paragraph={paragraph1}/>
            <CardWhite img={assegnazione} title={title2} paragraph={paragraph2} />
            <CardBlue img={tecnologia} title={title3} paragraph={paragraph3} />
            <CardWhite img={lista} title={title4} paragraph={paragraph4} />
            <CardBlue img={mappa} title={title5} paragraph={paragraph5} />
            <CardWhite img={firma} title={title6} paragraph={paragraph6} />
            <div style = {{backgroundColor: '#CAE5FF80', height: 'fit-content', width: '100vw', marginTop: '80px'}}>
                <Card />    
            </div>
            <Separator />
            <div className = 'background_pc_right'>
                <Carat side={'right'} title={title7} img={cloud} paragraph={paragraph7}/>
            </div>
            <div className = 'background_pc_left' >
                <Carat side={'left'} title={title8} img={app} paragraph={paragraph8} />
            </div>
            <Carat side={'white'} title={title9} img={hardware} paragraph={paragraph9} />

            <div className = 'contatti_tech'>
                <div style = {{width: '50%'}}>
                    <h>Sei pronto a iniziare?</h>
                </div>
                <Link to = '/contattaci' className = 'contatti_button'>
                    Contattaci
                </Link>               
            </div>
            <Footer />
        </div>
    )
}