import React from 'react'
import '../../styles/component/Tecnologia/Card.scss'
import incarichi from '../../assets/component/Tecnologia/Card/Incarichi.svg'
import assegnazione from '../../assets/component/Tecnologia/Card/Assegnazione.svg'
import tecnologia from '../../assets/component/Tecnologia/Card/Tecnologie.svg'
import inventario from '../../assets/component/Tecnologia/Card/Inventario.svg'
import mappa from '../../assets/component/Tecnologia/Card/Mappa.svg'
import conferma from '../../assets/component/Tecnologia/Card/Conferma.svg'
export default function Card(){

    return(
        <>
            <div className = 'main_card'>
                <div className = 'card'>
                    <div className = 'title_single_card'>
                        <img src = {incarichi} alt = ''></img>
                        <h>Creazione incarichi</h>
                    </div>
                    <p>
                        L'amministrazione aziendale utilizza il cloud per creare 
                        compiti specifici, indicando l’itinerario, 
                        il veicolo da utilizzare e i materiali o pezzi da caricare 
                        effettuare il servizio.
                    </p>
                </div>
                <div className = 'card'>
                    <div className = 'title_single_card'>
                        <img src = {assegnazione} alt = ''></img>
                        <h>Assegnazione incarichi</h>
                    </div>
                    <p>
                        Ogni incarico viene assegnato a ciascun dipendente 
                        attraverso un'applicazione dedicata.
                    </p>
                </div>
                <div className = 'card'>
                    <div className = 'title_single_card'>
                        <img src = {conferma} alt = ''></img>
                        <h>Conferma incarico completato</h>
                    </div>
                    <p>
                        L'amministrazione aziendale utilizza il cloud per creare compiti specifici, 
                        indicando l’itinerario, il veicolo da utilizzare e i materiali o pezzi da 
                        caricare effettuare il servizio.
                    </p>
                </div>
                <div className = 'card'>
                    <div className = 'title_single_card'>
                        <img src = {inventario} alt = ''></img>
                        <h>Lista automatizzata inventario</h>
                    </div>
                    <p>
                        Ogni dipendente, tramite l'app, accede a una lista dinamica e 
                        automatizzata degli elementi necessari per il compito assegnato,
                        con informazioni sulla posizione dei colleghi che hanno pezzi utili 
                        per l'intervento.
                    </p>
                </div>
                <div className = 'card'>
                    <div className = 'title_single_card'>
                        <img src = {mappa} alt = ''></img>
                        <h>Mappa interattiva</h>
                    </div>
                    <p>
                        Attraverso l'app, i dipendenti possono visualizzare sulla mappa la 
                        posizione dei colleghi e la disponibilità dei pezzi di inventario. 
                        Questo aiuta a ottimizzare gli spostamenti e a facilitare la 
                        collaborazione tra i membri del team.
                    </p>
                </div>
                <div className = 'card'>
                    <div className = 'title_single_card'>
                        <img src = {tecnologia} alt = ''></img>
                        <h>Tecnologia UHF di RFID</h>
                    </div>
                    <p>
                        Dinamic Inventory sfrutta la tecnologia UHF (Ultra High Frequency) 
                        di RFID (Radio-Frequency Identification) per tracciare e gestire 
                        gli inventari. Questa tecnologia può essere installata diretamente 
                        sui veicoli e sui componenti dell'inventario.
                    </p>
                </div>
                
            </div>
        </>
    )
}