import React from "react";
import '../../styles/component/Home/Separator.scss'
import BG from '../../assets/component/Home/Separator/BG.svg'

export default function Separator() {
    return(
        <>
            <div className = 'main_separator'>
                <img alt = '' src = {BG} style = {{width: '100vw'}}/>
            </div>
        </>
    )
}