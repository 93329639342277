import logo from '../assets/component/Header/logo.svg'
import tecnologia from '../assets/component/Header/tecnologia.svg'
import tecnologiaMobile from '../assets/component/Header/tecnologiaMobile.svg'
import contatti from '../assets/component/Header/contatti.svg'
import contattiMobile from '../assets/component/Header/contattiMobile.svg'
import video from '../assets/component/Header/video.svg'
import videoMobile from '../assets/component/Header/videoMobile.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../styles/component/Header.scss'

export default function Header(){
    const [home, setHome] = useState(true);
    const [tech, setTech] = useState(false);
    const [contact, setContact] = useState(false);
    
    const location = useLocation()
    
    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setHome(true);
                setTech(false);
                setContact(false);
                break;
            case '/tecnologia':
                setHome(false);
                setTech(true);
                setContact(false);
                break;
            case '/contattaci':
                setHome(false);
                setTech(false);
                setContact(true);
                break;
        }
    }, [location.pathname])

    const colorName = useCallback((pages) => {
        switch (pages) {
            case 'HOME':
                setHome(true);
                setTech(false);
                setContact(false);
                break;
            case 'TECH':
                setHome(false);
                setTech(true);
                setContact(false);
                break;
            case 'CONTACT':
                setHome(false);
                setTech(false);
                setContact(true);
                break;
        }
    }, [location.pathname])

    return(
        <div style = {{width: '100vw', display: 'flex', justifyContent: 'center', backgroundColor: '#2E3084', height: '90px', zIndex: '100', position: 'fixed'}}>
            <div className='header_mobile'>
                <div style = {{display:'flex'}}>
                    <Link to = '/' className = 'logo_mobile' style={{textDecoration: 'none'}}>
                        <div className = 'logo' onClick={() => colorName('HOME')}>
                            <img src = {logo} alt = '' />
                            <p>Dynamic Inventory</p>
                        </div>
                    </Link>
                </div>
                <div style = {{display: 'flex'}}>
                    <Link to = 'https://www.youtube.com/watch?v=e0WV6VABONc' className = 'link' target='_blank'>
                        <div className = 'container_button'>
                            <div className = 'mobile'>
                                <img src = {video} alt = ''></img>
                                <p style = {{color: '#B9E8FF'}}>Video</p>
                            </div>
                            <div className = 'pc'>
                                <img alt = '' src = {videoMobile} />
                                <p1 style = {{color: '#B9E8FF'}}>Video</p1>
                            </div>
                        </div>
                    </Link>
                    <Link to = '/tecnologia' className = 'link'>
                        <div className = 'container_button' onClick={() => colorName('TECH')}>
                            <div className = 'mobile'>
                                <img src = {tecnologia} alt = '' ></img>
                                <p style = {tech ? {color: '#F7A941', fontWeight: '700'} : {color: '#B9E8FF'}}>Tecnologia</p>
                            </div>
                            <div className = 'pc'>
                                <img alt = '' src = {tecnologiaMobile} />
                                <p1 style = {tech ? {color: '#F7A941', fontWeight: '700'} : {color: '#B9E8FF'}}>Servizio e tecnologia</p1>
                            </div>
                        </div>
                    </Link>
                    <Link to = '/contattaci' className = 'link'>
                        <div className = 'container_button'  onClick={() => colorName('CONTACT')}>
                            <div className = 'mobile'>
                                <img src = {contatti} alt = ''></img>
                                <p style = {contact ? {color: '#F7A941', fontWeight: '700'} : {color: '#B9E8FF'}}>Contatti</p> 
                            </div>
                            <div className = 'pc'>
                                <img alt = '' src = {contattiMobile} />
                                <p1 style = {contact ? {color: '#F7A941', fontWeight: '700'} : {color: '#B9E8FF'}}>Contattaci</p1>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )

}