import React from 'react'
import '../../styles/component/Tecnologia/CardBlue.scss'

export default function CardBlue({img, title, paragraph}){
    return (
        <div className = 'main_card_blue'>
            <img alt = '' src = {img} />
            <div className = 'text_card_blue'>
                <h>{title}</h>
                <p>{paragraph}</p>
            </div>
        </div>
    )
}