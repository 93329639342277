import React from 'react'
import '../../styles/component/Home/Title.scss'
import titleBG from '../../assets/component/Home/Title/titleBG.svg'
import titleBG_big from '../../assets/component/Home/Title/titleBG-PC.svg'
import { Link } from 'react-router-dom'

export default function Title() {
    return(
        <>
            <div className = 'main_title_mobile'>
                    <div className = 'text_mobile'>
                        <h1>Digitalizzazione della flotta aziendale</h1>
                    </div>
                    <div className = 'BG_mobile'>
                        <img alt = '' src = {titleBG} />
                    </div>
                    <div className= 'text_mobile'>
                        <p>
                            <span>Servizio Cloud</span> e tecnologia <span>RFID
                            UHF</span> all'avanguardia, tutto in un'unica piattaforma.
                        </p>
                        <p>
                            <span>Semplifichiamo</span> i tuoi processi e 
                            <span> ottimizziamo</span> la tua produttività.
                        </p>
                    </div>
            </div>
            <div className = 'main_title'>
                <div className = 'text'>
                    <h1>Digitalizzazione della flotta aziendale</h1>
                    <p>
                        Servizio Cloud e tecnologia RFID UHF all'avanguardia,
                        tutto in un’unica piattaforma. 
                    </p>
                    <p>
                        Semplifichiamo i tuoi processi e 
                        ottimizziamo la tua produttività.
                    </p>
                    <Link to = '/contattaci' style = {{textDecoration: 'none'}}>
                        <button>Contattaci</button>
                    </Link>
                </div>
                <div>
                    <img alt = '' src = {titleBG_big}/>
                </div>
            </div>
        </>

    )
}